import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";

const Infobar = ({
  label,
  buttonLink,
  buttonLabel,
  onClose,
}: {
  label: string;
  buttonLink?: string;
  buttonLabel?: string;
  onClose?: () => void;
}) => (
  <Box
    sx={{
      height: 50,
      width: "100%",
      backgroundColor: "white",
      borderBottom: "1px solid #e0e0e0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: 14,
      paddingX: 4,
      position: "sticky",
      color: "grey.800",
    }}
  >
    <Box
      sx={{
        display: "flex",
        mx: "auto",
        alignItems: "center",
        gap: 2,
      }}
    >
      {label}
      {buttonLink && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            window.open(buttonLink, "_blank");
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
    {onClose && (
      <IconButton onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    )}
  </Box>
);

export default Infobar;
